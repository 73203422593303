import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import {
  // ourSponsorsContainer,
  ourSponsorsImage,
  ourSponsorsContactUs,
} from "../styles/home.module.css"
// import sponsor from "../images/sponsor.png"
import { Swiper, SwiperSlide } from "swiper/react"
export const OurSponsors = props => {
  const [count, setCount] = useState(6)

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    const width = isBrowser ? window?.innerWidth : 0
    if (width > 1300) {
      setCount(6)
    } else if (width <= 1300 && width > 950) {
      setCount(4)
    } else if (width <= 950 && width > 550) {
      setCount(2)
    } else {
      setCount(1)
    }
  }, [])
  const { data } = props
  return (
    <div className={`px-4 sm:px-8 md:px-12 lg:px-16 pt-6 md:pt-10`}>
      <p className={`heading`}>Our Sponsors</p>
      {/* <div className={`${ourSponsorsImageContainer}`}> */}
      <Swiper slidesPerView={count} autoplay={true}>
        {data.map((image, i) => (
          <SwiperSlide key={i}>
            <div className="flex items-center justify-center">
            <img
              src={image.url}
              alt="sponsors"
              className={`${ourSponsorsImage}`}
            />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* </div> */}
      <div className={`${ourSponsorsContactUs}`}>
        Interested in sponsoring up? Contact Us{" "}
        <Link href="/about#contact">right here</Link>
      </div>
    </div>
  )
}
