import React from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  event_card,
  event_desc,
  event_title,
  event_container,
  event_btn,
  event_img_collage,
} from "../styles/eventcard.module.css"
import pulzionImg from "../images/pulzion.svg";
import sigImg from "../images/sig.svg";
import pasckathonImg from "../images/blog_banner.png";
import { Link } from "gatsby"

const EventCard = props => {
  const { title, desc, btn_label, index, link, buttonVisible } = props
  return (
    <div className={`${event_container} lg:rounded-3xl`}>
      <div
        className={`${event_card} flex ${
          index % 2 === 0 ? "flex-col-reverse" : "flex-col"
        } ${index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"}`}
      >
        <div className={`${event_desc} w-full lg:w-1/2 lg:text-left`}>
          <h4 className={`${event_title}`}>{title}</h4>
          <p className={`text-justify lg:text-left`}>{desc}</p>
          {buttonVisible && (
            <Link to={link}>
              <button className={`${event_btn} `}>{btn_label}</button>
            </Link>
          )}
        </div>
        <div
          className={`hidden lg:flex text-center justify-content-center align-items-center ${event_img_collage}`}
        >
          <LazyLoadImage
            alt="test"
            src={`${
              index === 0
                ? pulzionImg
                : index === 1
                ? pasckathonImg
                : sigImg
            }`}
          />
        </div>
      </div>
    </div>
  )
}

export { EventCard }
