import React from "react"
import ContactForSponsors from "./ContactForSponsors"
import ContactUsForm from "./ContactUsForm"
import ReachUs from "./ReachUs"
import Socials from "./Socials"

const ContactUs = ({ isMobile }) => {
  return (
    <div
      className={`px-4 sm:px-8 md:px-12 lg:px-16 mb-20 relative`}
      id="contact"
      style={{ marginTop: "-100px", paddingTop: "100px" }}
    >
      <img
        src="/timeline-right.png"
        className="absolute top-20 right-0 hidden md:block w-80"
        style={{ zIndex: "-100" }}
        alt="contact-bg"
      />
      <img
        src="/timeline-left.png"
        className="absolute bottom-10 left-0 hidden md:block -z-10"
        alt="contact-bg"
        style={{ zIndex: "-100" }}
      />
      <p className={`heading md:mb-10 xs:mb-6`}>Contact Us</p>
      <div className={`md:grid md:grid-cols-2 gap-y-12 gap-x-12 z-20`}>
        <div className="xs:mb-10 md-mb-0">
          <ReachUs isMobile={isMobile} />
        </div>
        <div className="xs:block md:hidden xs:mb-10 md-mb-0">
          <ContactForSponsors isMobile={isMobile} />
        </div>
        <div className="xs:block md:hidden xs:mb-10 md-mb-0">
          <Socials isMobile={isMobile} />
        </div>
        <div>
          <ContactUsForm isMobile={isMobile} />
        </div>
        <div className="xs:hidden md:block">
          <ContactForSponsors isMobile={isMobile} />
        </div>
        <div className="xs:hidden md:block">
          <Socials isMobile={isMobile} />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
