import React from "react";
import
{
  announcementCard,
  announcementHeading,
  announcementContent,
  announcementDate,
} from "../styles/home.module.css";
export const AnnouncementCard = props =>
{
  const { heading, content, date } = props.data;
  return (
    <>
      <div className={`${announcementCard} md:h-80 justify-start md:justify-center`}>
        <div className={`${announcementHeading}`}>{heading}</div>
        <div className={`${announcementContent} text-justify`}>{content}</div>
        <div className={`${announcementDate}`}>{`-${date}`}</div>
      </div>
    </>
  );
};
