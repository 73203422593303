import React from "react"
import {
  date,
  timeline__content,
  timeline__text,
  left__arrow,
  right__arrow,
} from "../../styles/timeline.module.css"

const TimelineCard = ({ left, data }) => {
  return (
    <div className="flex justify-center items-center rounded-2xl relative order-1 w-5/12">
      <div
        className={`flex flex-col items-center justify-center ${date} ${
          left ? "rounded-l-2xl" : "rounded-r-2xl"
        } p-4 self-stretch ${left ? "order-0" : "order-2"}`}
        style={{
          background: "#eceff1",
          border: "2px solid #134467",
          boxShadow: "0px 8px 16px rgba(69, 90, 100, 0.25)",
        }}
      >
        <p className="text-center heading6 bold w-20">{data.date}</p>
      </div>
      <div
        className={`flex flex-col justify-center items-start p-4 ${
          left ? "rounded-r-2xl" : "rounded-l-2xl"
        } order-1 ${timeline__content}`}
      >
        <div className="flex items-start self-stretch my-2">
          <span class="material-icons w-10 h-10 mx-2 heading5 bold">
            outlined_flag
          </span>
          <p className="heading5 bold flex-1">{data.title}</p>
        </div>
        <p className={`paragraph2 medium self-stretch my-2 ${timeline__text}`}>
          {data.content}
        </p>
      </div>
      <div
        className={left ? `order-2 ${left__arrow}` : `order-0 ${right__arrow}`}
      ></div>
    </div>
  )
}

export default TimelineCard
