import React from "react"
import {
  container,
  input,
  error_icon,
  icon_container,
  give_border,
} from "../styles/formfield.module.css"

const AppFormField = ({
  label,
  value,
  placeholder,
  onChange,
  error,
  errorIcon,
  icon,
  input_icon,
  ...otherProps
}) => {
  return (
    <div className={container}>
      <p className={`paragraph2 medium`}>{label}</p>
      <div className={`flex items-center`}>
        <input
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          {...otherProps}
          className={`${input} paragraph3 light my-2 flex-grow ${
            !icon && give_border
          }`}
        />
        {icon && (
          <p className={icon_container}>
            <span className={`material-icons ${input_icon}`}>{icon}</span>
          </p>
        )}
      </div>
      {error && (
        <div className={`flex items-center`}>
          <span className={`${error_icon} material-icons mx-1`}>
            {errorIcon}
          </span>
          <p className={`caption light`}>{error}</p>
        </div>
      )}
    </div>
  )
}

export default AppFormField
