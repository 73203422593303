import React, { useState, useEffect } from "react"
import {Helmet} from "react-helmet";

import About from "../components/About"
import Banner from "../components/Banner"
import ContactUs from "../components/ContactUs"
// import HorizontalScroll from "../components/HorizontalScroll"
import Layout from "../components/Layout"

const AboutPage = () => {
  const isBrowser = typeof window !== "undefined"
  const [isMobile, setIsMobile] = useState(
    isBrowser ? window?.innerWidth <= 768 : false
  )

  useEffect(() => {
    if (isBrowser) {
      window?.addEventListener("resize", function (event) {
        setIsMobile(window?.innerWidth <= 768)
      })
    }
  }, [setIsMobile, isBrowser])
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter | About</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Banner
        title="Who We Are?"
        text={`PICT ACM Student Chapter aims to solve the problems of the future in the present with technical proficiency. We emphasize the learning experience of our members by conducting activities like Workshops, Guest Lectures, Events, etc.`}
        image="about.png"
      />
      <About isMobile={isMobile} />
      {/* <HorizontalScroll isMobile={isMobile} /> */}
      <ContactUs />
    </Layout>
  )
}

export default AboutPage
