import React from "react"
import {
  blogcard,
  blogCardImg,
  bloggerName,
  blogTitle,
  blogExcerpt,
  blogDate,
  blogDomainChip,
} from "../styles/blogPage.module.css"
import { navigate } from "gatsby"
import moment from "moment"

export default function BlogCard({ blog }) {
  return (
    <button
      className={`${blogcard} transform transition duration-150 hover:-translate-y-6 cursor-pointer`}
      onClick={() => navigate(`/blog/${blog._id}`)}
    >
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          padding: "0px 10px 0px 10px",
        }}
      >
        <div className={`${bloggerName}`}>{blog.ownerName}</div>
      </div>

      <div className={`${blogTitle}`}>{blog.heading}</div>
      <div className={`flex flex-wrap gap-2`}>
        {blog.tags.map(tag => (
          <div className={`${blogDomainChip} px-2`} key={tag}>
            <span className={``}>{tag}</span>
          </div>
        ))}
      </div>
      <p className={`${blogExcerpt}`}>{blog.abstract}</p>
      <img
        src={blog.thumbnailLink}
        className={`${blogCardImg}`}
        alt={blog.heading}
      />
      <div className={`${blogDate}`}>
        Date: {moment(blog.createdAt).format("DD MMMM, YYYY")}
      </div>
    </button>
  )
}
