import React from "react"
// import { icon } from "../styles/social.module.css"
import xLogo from '../images/icons/x_logo.png'
const Socials = ({ isMobile }) => {

  return (
    <div className="max-w-xl">
      <p
        className={`subHeading md:mb-6 xs:mb-4`}
      >
        Our Socials
      </p>
      <div className={`grid grid-cols-2 gap-y-4`}>
        <a
          href="https://m.facebook.com/1715207248701959/"
          target="_blank"
          rel="noreferrer"
          className={`flex items-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current mr-2"
          >
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
          </svg>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph2 medium"
            }`}
          >
            Facebook
          </p>
        </a>
        <a
          href="https://instagram.com/acm.pict?utm_medium=copy_link"
          target="_blank"
          rel="noreferrer"
          className={`flex items-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current mr-2"
          >
            <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
          </svg>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph2 medium"
            }`}
          >
            Instagram
          </p>
        </a>
        <a
          href="https://www.linkedin.com/in/pict-acm-student-chapter-09004a132/"
          target="_blank"
          rel="noreferrer"
          className={`flex items-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            className="fill-current mr-2"
          >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph2 medium"
            }`}
          >
            LinkedIn
          </p>
        </a>
        <a
          href="https://twitter.com/_pict_acm_?t=vF0OMzfqp-ihxHdk0b1Plg&s=09"
          target="_blank"
          rel="noreferrer"
          className={`flex items-center`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24"
            height="24" viewBox="0 0 448 512" ><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/></svg>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph2 medium"
            } ml-2`}
          >
            Twitter
          </p>
        </a>
        <a
          href="https://www.youtube.com/channel/UCGd_SUiVRCi3UxOE9GtbcMA"
          target="_blank"
          rel="noreferrer"
          className={`flex items-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current mr-2"
          >
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z" />
          </svg>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph2 medium"
            }`}
          >
            YouTube
          </p>
        </a>
      </div>
    </div>
  )
}

export default Socials
