import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import AppButton from "./AppButton";
import * as style from "../styles/header.module.css";
import outstandingWebsiteBadge from '../images/OutstandingWebsiteBanner.png';
import logo from "../images/logo.png";
import Login from "./Modals/Login";
import Signup from "./Modals/Signup";
import authContext from "../routes/context";
import API from "../apis";
import { useGoogleLogout } from "react-google-login";
import { errorAlert } from "./Alert";
import { clientId } from "./GoogleAuth";
import modalContext from "../routes/ModalContext";

const links = [
  {
    id: 1,
    text: "Home",
    to: "/",
  },
  {
    id: 2,
    text: "What We Do",
    to: "/whatwedo",
    subLinks: [
      {
        text: "Events",
        to: "/whatwedo#events",
      },
      {
        text: "Domains",
        to: "/whatwedo#domains",
      },
    ],
  },
  {
    id: 3,
    text: "Our Team",
    to: "/ourteam",
    // subLinks: [
    //   {
    //     text: "2022",
    //     to: "/ourteam?section=2022",
    //   },
    //   {
    //     text: "2021",
    //     to: "/ourteam?section=2021",
    //   },
    //   {
    //     text: "2020",
    //     to: "/ourteam?section=2020",
    //   },
    // ],
  },
  {
    id: 4,
    text: "Who We Are",
    to: "/about",
    subLinks: [
      {
        text: "ACM",
        to: "/about#acm",
      },
      {
        text: "PASC",
        to: "/about#pasc",
      },
      // {
      //   text: "Gallery",
      //   to: "/about#gallery",
      // },
      {
        text: "Contact Us",
        to: "/about#contact",
      },
    ],
  },
  {
    id: 5,
    text: "Join PASC",
    to: "/join",
  },
  {
    id: 6,
    text: "Achievements",
    to: "/achievements",
  },
  {
    id: 7,
    text: "Glimpses",
    to: "/glimpses",
  },
  //commented below
  // {
  //   id: 7,
  //   text: "Blogs",
  //   to: "/blog",
  // },
];

function Header()
{
  const { open, setOpen } = useContext(modalContext);
  const [menu, setMenu] = useState(false);
  const [drop, setDrop] = useState();
  const { authState, setAuthState } = useContext(authContext);
  const { signOut } = useGoogleLogout({
    clientId: clientId,
    cookiePolicy: "single_host_origin",
    onLogoutSuccess: () => console.log("Logged out successfully"),
    onFailure: () => console.log("Logout process failed"),
  });
  const isBrowser = typeof window !== "undefined";

  const logout = async ({ logoutAll = false }) =>
  {
    try
    {
      const auth = isBrowser ? JSON.parse(localStorage.getItem("auth")) : {};
      if (auth && auth.google_token)
      {
        signOut();
      }
      if (logoutAll)
      {
        await API.logoutAll();
      } else
      {
        await API.logout();
      }
      if (isBrowser)
      {
        localStorage.removeItem("auth");
      }
      API.setAuthToken("");
      setAuthState({
        ...authState,
        isAuthenticated: false,
        user: null,
        authType: "",
      });
    } catch (error)
    {
      console.log(error.message);
      errorAlert({
        title: "Error",
        subtitle: "Unable to logout. Please try again",
      });
    }
  };

  return (
    <>
      <>
        <header className={`w-full h-20 z-40 sticky top-0 ${style.header}`}>
          <img className={`${style.bestWebsiteBadge}`} src={outstandingWebsiteBadge} alt="outstandingWebsiteBadge" />
          <div>
            <div
              className={`flex items-center justify-between my-auto px-4 sm:px-8 md:px-12 lg:px-16 py-4`}
            >
              <button className="block lg:hidden" onClick={() => setMenu(!menu)}>
                <span className="material-icons">{menu ? "close" : "menu"}</span>
              </button>
              <Link to="/">
                <img src={logo} alt="logo" className={style.logo} />
              </Link>
              <nav className="hidden lg:block">
                <ul className="flex items-center gap-4 xl:gap-5">
                  {links.map(link => (
                    <li key={link.text} className={`${style.opener}`}>
                      <Link
                        to={link.to}
                        className={`flex flex-col items-center gap-1 ${style.link
                          } ${isBrowser
                            ? link.to === window?.location?.pathname
                              ? style.active
                              : ""
                            : ""
                          }`}
                      >
                        <span className="flex items-center gap-1 text-sm lg:text-xl ">
                          <span>{link.text}</span>
                          {link.subLinks && (
                            <span className={`material-icons`}>
                              arrow_drop_down
                            </span>
                          )}
                        </span>
                        <span className={style.header__underline}></span>
                      </Link>
                      <ul className={`${style.dropdown}`}>
                        {link.subLinks &&
                          link.subLinks.map(sublink => (
                            <li key={sublink.text}>
                              <Link
                                to={sublink.to}
                                className={`flex flex-col items-center gap-1 text-sm lg:text-xl  ${style.link
                                  } ${isBrowser
                                    ? sublink.to ===
                                      window?.location?.pathname +
                                      window?.location?.hash
                                      ? style.active
                                      : ""
                                    : ""
                                  }`}
                              >
                                <span>{sublink.text}</span>
                                <span className={style.header__underline}></span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="hidden lg:block">
                {authState?.isAuthenticated ? (
                  <nav className="hidden lg:block">
                    <ul className="flex items-center gap-4">
                      <li className={`${style.opener} cursor-pointer`}>
                        <span className="flex items-center gap-1 text-sm lg:text-xl">
                          <span>Welcome {authState.user?.name}</span>
                          <span className={`material-icons`}>
                            arrow_drop_down
                          </span>
                        </span>
                        <span className={style.header__underline}></span>
                        <ul
                          className={`${style.dropdown} ${style.profile_dropdown}`}
                        >
                          {/* eslint-disable-next-line  */}
                          <li
                            className={`flex flex-col items-center gap-1 text-sm lg:text-xl ${style.link}`}
                            onClick={logout}
                          >
                            <span>Logout</span>
                            <span className={style.header__underline}></span>
                          </li>
                          {/* eslint-disable-next-line  */}
                          <li
                            className={`flex flex-col items-center gap-1 text-sm lg:text-xl ${style.link}`}
                            onClick={() => logout({ logoutAll: true })}
                          >
                            <span>Logout all devices</span>
                            <span className={style.header__underline}></span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                ) : (
                  <div></div>
                  // <AppButton
                  //   title="log in"
                  //   size="medium"
                  //   icon="arrow_forward"
                  //   variant="primary"
                  //   disabled // changed button to disabled for now 
                  //   onClick={() => setOpen("login")}
                  // />
                )}
              </div>
              <div className="block lg:hidden"></div>
            </div>
          </div>
        </header>
        {menu && (
          <div
            className={`fixed top-20 w-64 min-h-screen ${style.sidebar} block lg:hidden`}
          >
            <nav>
              <ul className="flex items-start flex-col gap-4 py-10">
                {links.map(link =>
                {
                  return (
                    <li key={link.text} className={`${style.opener}`}>
                      <span
                        className={`flex items-center justify-start text-xl ${style.link
                          } ${isBrowser
                            ? link.to === window?.location?.pathname
                              ? style.active
                              : ""
                            : ""
                          }`}
                      >
                        <span
                          className={`${style.header__underline__mobile} h-6`}
                        ></span>
                        <Link to={link.to} className="pl-4">
                          {link.text}
                        </Link>
                        {link.subLinks && (
                          <button
                            className={`material-icons`}
                            onClick={() =>
                              setDrop(drop !== link.id ? link.id : undefined)
                            }
                          >
                            arrow_drop_down
                          </button>
                        )}
                      </span>
                      {drop === link.id && (
                        <ul className={`flex flex-col gap-4`}>
                          {link.subLinks &&
                            link.subLinks.map(sublink => (
                              <li key={sublink.text} className="ml-4">
                                <Link
                                  to={sublink.to}
                                  className={`flex items-center text-sm lg:text-xl  ${style.link
                                    } ${isBrowser
                                      ? sublink.to ===
                                        window?.location?.pathname +
                                        window?.location?.hash
                                        ? style.active
                                        : ""
                                      : ""
                                    }`}
                                >
                                  <span
                                    className={`${style.header__underline__mobile} h-6`}
                                  ></span>
                                  <span className="pl-4">{sublink.text}</span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
                {authState.isAuthenticated ? (
                  <>
                    <li className={`${style.opener}`}>
                      <span
                        className={`flex items-center justify-start text-xl ${style.link
                          } ${open ? style.active : ""}`}
                      >
                        <span
                          className={`${style.header__underline__mobile} h-6`}
                        ></span>
                        <span className="pl-4">
                          <AppButton
                            title={`Logout`}
                            size="medium"
                            icon="logout"
                            variant="primary"
                            onClick={logout}
                          />
                        </span>
                      </span>
                    </li>
                    <li className={`${style.opener}`}>
                      <span
                        className={`flex items-center justify-start text-xl ${style.link
                          } ${open ? style.active : ""}`}
                      >
                        <span
                          className={`${style.header__underline__mobile} h-6`}
                        ></span>
                        <span className="pl-4">
                          <AppButton
                            title={`Logout all devices`}
                            size="medium"
                            icon="logout"
                            variant="primary"
                            onClick={() => logout({ logoutAll: true })}
                          />
                        </span>
                      </span>
                    </li>
                  </>
                ) : (
                  <li className={`${style.opener}`}>
                    <span
                      className={`flex items-center justify-start text-xl ${style.link
                        } ${open === "login" || open === "signup" ? style.active : ""
                        }`}
                    >
                      <span
                        className={`${style.header__underline__mobile} h-6`}
                      ></span>
                      {/* <button onClick={() => setOpen("login")} className="pl-4">
                      Login/SignUp
                    </button> */}
                    </span>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
        {open === "login" && (
          <>
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
              id="my-modal"
            ></div>
            <Login setOpen={setOpen} />
          </>
        )}
        {open === "signup" && (
          <>
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
              id="my-modal"
            ></div>
            <Signup setOpen={setOpen} />
          </>
        )}
      </>
      {/* <marquee className="flex py-3">Important Announcement for PASC Alumni: Please <span className="font-bold underline"><Link to="/join#alumni">click here</Link></span></marquee> */}
    </>
  );
}

export default Header;
