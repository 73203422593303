import React, { Fragment } from "react"
import { benefits } from "./Join_Pasc_data"
import "../styles/join_pasc.css"
import benefitLeft from "../images/benefits-left.png"
import benefitRight from "../images/benefits-right.png"

const Benefits = () => {
  return (
    <div>
      <img
        src={benefitLeft}
        className="absolute top-3/5 left-0 hidden lg:block"
        alt="pasc"
      />
      <img
        src={benefitRight}
        className="absolute top-3/5 right-0 hidden lg:block"
        alt="pasc"
      />
      <div className="px-4 sm:px-8 md:px-12 lg:px-16 mx-auto py-10">
        <p className={`heading`}>Membership Benefits</p>
        <div className="pt-10 grid md:grid-cols-2 xl:grid-cols-3 gap-10 md:gap-14">
          {benefits.map(benefit => {
            return (
              <Fragment key={benefit.heading}>
                <div className="benefit-card w-full ">
                  <div>
                    <span className="material-icons">
                      <i className="flag-icon">outlined_flag</i>
                    </span>
                  </div>
                  <div>
                    <h1 className={`heading6 semi-bold md:heading5 md:bold `}>
                      {benefit.heading}
                    </h1>
                    <div className="flex flex-col">
                      <p
                        className={`paragraph2 regular md:paragraph2 md:medium text-black`}
                      >
                        {benefit.description}
                      </p>
                    </div>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Benefits
