import React, { useState } from "react"
import AppButton from "./AppButton"
import AppFormField from "./AppFormField"

const ContactUsForm = ({ isMobile }) => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [messages, setMessages] = useState()

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   if (name && email && messages) {
  //   }
  // }

  return (
    <>
      <p className={`subHeading md:mb-6 xs:mb-4`}>Send Us A Message</p>
      <form action="https://www.actionforms.io/e/r/pict-acm-student-chapter-test">
        <div className={`md:mb-6 xs:mb-4`}>
          <AppFormField
            name="Name"
            value={name}
            label="Name"
            placeholder="Name"
            type="text"
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={`md:mb-6 xs:mb-4`}>
          <AppFormField
            name="Email"
            value={email} //changed value to email from name
            label="Email"
            placeholder="Email"
            type="email" // change its type to email from text
            onChange={e => setEmail(e.target.value)} //changed value to setEmail from setName
          />
        </div>
        <div className={`md:mb-6 xs:mb-4`}>
          <AppFormField
            name="Message"
            value={messages} //changed value to email from name
            label="Your Message"
            placeholder="Your Message"
            type="text"
            onChange={e => setMessages(e.target.value)} //changed value to setMesaages from setName
          />
        </div>
        <div>
          <AppButton variant="primary" size="large" title="Send" icon="send" />
        </div>
      </form>
    </>
  )
}

export default ContactUsForm
