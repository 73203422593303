import React from "react"
import {Helmet} from "react-helmet";
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import {
  bestChapterBanner,
  joinCTAContainer,
  joinCTAText,
} from "../styles/home.module.css"
import BestStudentChapterBanner from "../images/bestChapterBanner.png"
import { sponsors } from "../data/sponsors.js"
import announcementData from "../data/announcements.json"
import AppButton from "../components/AppButton"
import { OurSponsors } from "../components/OurSponsors"
import { AnnouncementContainer } from "../components/AnnouncementContainer"
import { SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import "swiper/css"
import Timeline from "../components/Timeline/Timeline"
import Slider from "react-slick"
import Tooltip from "../components/Tooltip";
function Home() {
  const isBrowser = typeof window !== "undefined"
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
  }
  SwiperCore.use([Autoplay])
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Slider {...settings}>
        <SwiperSlide>
          <Banner
            title="PASC"
            text={`PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them.
            PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future. 
            `}
            image="home_1.png"
          />
        </SwiperSlide>
        {/* <SwiperSlide>
          <Banner
            title="Home"
            text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae, vitae sit ullamcorper in. Urna faucibus molestie sit urna quis vitae, integer. Scelerisque cras quam sed mollis sed at quam a. Cursus porta purus ut consectetur dolor ullamcorper orci dolor`}
            image="rise_pasc.png"
          />
        </SwiperSlide> */}
        <SwiperSlide>
          <Banner
            title="PASC-W"
            text={`PASC-W is a special wing of PASC which organizes its event different events to promote diversity in technology. Through PASC-W we encourage gender equality and women empowerment. PASC-W promotes full engagement of women in the technical as well as non-technical domains. Through its collaborations and initiatives, PASC-W provides a large range of services to all ACM members along with pushing the advancement of contribution of women in the field of computing.`}
            image="home_2.png"
          />
        </SwiperSlide>
      </Slider>
      <AnnouncementContainer data={announcementData} />
      {/* <img
        src={BestStudentChapterBanner}
        alt="banner"
        className={`${bestChapterBanner}`}
      /> */}
      <OurSponsors data={sponsors} />
      <Timeline showRead={true} />
      <div
        className={`${joinCTAContainer} px-4 sm:px-8 md:px-12 lg:px-16 py-4 flex items-center justify-between gap-4`}
      >
        <h4
          className={`${joinCTAText} font-normal md:font-light text-sm md:text-3xl`}
        >
          Become a part of such awesomeness
        </h4>
          <AppButton
            title="join PASC"
            onClick={() => {
              if (isBrowser) {
                window?.open("https://pict.acm.org/register/")
              }
            }}
          />
      </div>
    </Layout>
  )
}

export default Home
