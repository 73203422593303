import React, { useState } from "react"
import { EditorState } from "draft-js"
import { Formik } from "formik"
import * as Yup from "yup"
import AppFormField from "../AppFormField"
import AppButton from "../AppButton"
import { Editor } from "react-draft-wysiwyg"
import Select from "react-select"

const validationSchema = Yup.object().shape({
  heading: Yup.string().trim().required().label("Title"),
  imageLink: Yup.string().trim().required().label("Image Link"),
  thumbnailLink: Yup.string().trim().required().label("Thumbnail Link"),
  tags: Yup.array().required().label("Tags"),
  abstract: Yup.string().trim().required().label("Abstract")
})

const options = [
  { label: "Web Development", value: "Web Development" },
  { label: "App Development", value: "App Development" },
  { label: "Cloud Computing", value: "Cloud Computing" },
  { label: "Devops", value: "Devops" },
  { label: "Competitive Programming", value: "Competitive Programming" },
  { label: "Machine Learining", value: "Machine Learining" },
  { label: "Artificial Intelligence", value: "Artificial Intelligence" },
  { label: "Data Science", value: "Data Science" },
]

const BlogForm = ({ handleSubmit }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  return (
    <Formik
      initialValues={{
        heading: "",
        imageLink: "",
        thumbnailLink: "",
        abstract: "",
        tags: [],
      }}
      onSubmit={values => handleSubmit(values, editorState)}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues,
        errors,
        touched,
      }) => {
        const selectedOption = values.tags.map(item => ({
          label: options.find(option => option.value === item)?.label,
          value: item,
        }))
        const handleMultipleChange = selectedOption => {
          setValues({
            ...values,
            tags: selectedOption.map(item => item.value),
          })
        }
        return (
          <form
            className="pt-3 lg:w-1/3 xl:w-5/12 sm:m-9 px-6 md:w-1"
            style={{ width: "450px" }}
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(values)
            }}
          >
            <div className="mx-auto md:auto md:auto">
              <div className="flex flex-wrap">
                <h1 className={`heading`}>Write blog</h1>
              </div>

              <div className="pt-6 pb-8 mb-4 flex flex-col gap-4">
                <div>
                  <AppFormField
                    name="heading"
                    value={values.heading}
                    label="Title"
                    placeholder="Add title"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.heading && errors.heading && (
                    <p className={`paragraph3 light`}>{errors.heading}</p>
                  )}
                </div>
                <div>
                  <AppFormField
                    name="imageLink"
                    value={values.imageLink}
                    label="Image"
                    placeholder="Add Image"
                    type="url"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.imageLink && errors.imageLink && (
                    <p className={`paragraph3 light`}>{errors.imageLink}</p>
                  )}
                </div>
                <div>
                  <AppFormField
                    name="thumbnailLink"
                    value={values.thumbnailLink}
                    label="Thumbnail"
                    placeholder="Add thumbnail"
                    type="url"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.thumbnailLink && errors.thumbnailLink && (
                    <p className={`paragraph3 light`}>{errors.thumbnailLink}</p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <p className="paragraph2 medium">Tags</p>
                  <Select
                    name="tags"
                    isMulti={true}
                    value={selectedOption}
                    onChange={handleMultipleChange}
                    options={options}
                    className="paragraph3 light my-2 rounded-md"
                    onBlur={handleBlur}
                  />
                  {touched.tags && errors.tags && (
                    <p className={`paragraph3 light`}>{errors.thumbnailLink}</p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <p className={`paragraph2 medium`}>Abstract</p>
                  <textarea
                    className="paragraph3 light my-2 border border-black rounded-md px-2 py-2"
                    rows={6}
                    value={values.abstract}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="abstract"
                    placeholder="Write Your Abstract"
                  ></textarea>
                  {touched.abstract && errors.abstract && (
                    <p className={`paragraph3 light`}>{errors.abstract}</p>
                  )}
                </div>
                <Editor
                  editorState={editorState}
                  editorClassName="w-full border-gray-700 border rounded-md bg-white px-2 py-2"
                  placeholder="Your Blog Content"
                  onEditorStateChange={setEditorState}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "emoji",
                      "remove",
                      "history",
                    ],
                  }}
                />
                <br />
                <AppButton title="Submit" style={{ width: "103px" }} />
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default BlogForm
