import React, { createContext, useEffect, useState } from "react"
import API from "../apis"

const initialState = {
  isAuthenticated: false,
  setIsAuthenticated: value => {},
  user: null,
  authType: "",
}

const authContext = createContext(initialState)
authContext.displayName = "AuthContext"

export const AuthContextProvider = ({ children }) => {
  const isBrowser = typeof window !== "undefined"
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    authType: "",
  })
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const user = isBrowser
      ? JSON.parse(localStorage.getItem("auth"))
      : undefined
    if (user) {
      API.setAuthToken(user.token)
      setAuthState({
        isAuthenticated: true,
        user: user.user,
        authType: user.google_token ? "google" : "normal",
      })
    }
    setLoading(false)
  }, [isBrowser, setLoading])

  return !loading ? (
    <authContext.Provider value={{ authState, setAuthState }}>
      {children}
    </authContext.Provider>
  ) : (
    <></>
  )
}

export default authContext
