import React, { useState } from "react";
import pasc from "../images/logo.png";
import acm from "../images/acm.png";
import linkedin from "../images/icons/linkedin.png";
import facebook from "../images/icons/facebook.png";
import insta from "../images/icons/instagram.png";
import twitter from "../images/icons/x_logo.png";
import env from "../images/icons/envelope.png";
import youtube from "../images/icons/youtube.png";
import pascw from "../images/PASC-W.png";
import { contentwidth } from "../styles/header.module.css";
import Developer from "./Developer";

const icons = [
  {
    icon: facebook,
    link: "https://m.facebook.com/1715207248701959/",
  },
  {
    icon: insta,
    link: "https://instagram.com/acm.pict?utm_medium=copy_link",
  },
  {
    icon: linkedin,
    link: "https://www.linkedin.com/in/pict-acm-student-chapter-09004a132/",
  },
  {
    icon: twitter,
    link: "https://twitter.com/_pict_acm_?t=vF0OMzfqp-ihxHdk0b1Plg&s=09",
  },
  {
    icon: youtube,
    link: "https://www.youtube.com/channel/UCGd_SUiVRCi3UxOE9GtbcMA",
  },
];

const Footer = () =>
{
  const date = new Date();
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        className="py-14"
        style={{ backgroundColor: "#263238", color: "#FFFFFF" }}
      >
        <div className="px-4 sm:px-8 md:px-12 lg:px-16">
          <div className="flex flex-col md:flex-row justify-between gap-8 mb-10">
            <div className={`flex flex-col ${contentwidth}`}>
              <div>
                <img src={pasc} alt="I" className="h-14 md:h-20 w-auto" />
              </div>
              <p className="paragraph3 light mt-4  ">
                PICT ACM Student Chapter is an auxiliary organization of ACM.
                ACM-PICT is one of the most reputed and distinguished technical
                chapters working with vision and determination since its
                inception in 2011.
              </p>
            </div>
            <div className={`flex flex-col ${contentwidth}`}>
              <div>
                <img
                  src={pascw}
                  alt="I"
                  className="h-10 md:h-16 mb-3.5 w-auto"
                />
              </div>
              <p className="paragraph3 light mt-4 ">
                The PICT ACM Student Chapter - Women in Technology has been
                established as the sister body of the PICT ACM Student Chapter
                in 2018 with an aim to emancipate women and boost them in
                technological and computing fields.
              </p>
            </div>
            <div className={`flex flex-col ${contentwidth}`}>
              <div>
                <img src={acm} alt="I" className="h-14 md:h-20 w-auto" />
              </div>
              <div className="flex flex-col justify-between gap-4">
                <p className="paragraph3 light mt-4">
                  ACM is the world's largest educational and scientific
                  computing society, aimed at increasing the level and
                  visibility of ACM activities across the globe.
                </p>
                <a
                  href="https://dl.acm.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="underline paragraph3 light"
                >
                  ACM Digital Library
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-8 ">
            <div className={`flex flex-col ${contentwidth}`}>
              <p className="sub-heading medium mb-4">
                Follow us on Social Media
              </p>
              <div className="flex flex-row gap-4">
                {icons.map(val =>
                {
                  return (
                    <a
                      href={val.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={val.icon} alt="S" className={`w-6 md:w-auto ${val.icon === twitter ? 'x-logo' : ''}`} />
                    </a>
                  );
                })}
              </div>
            </div>
            <div
              className={`flex flex-col hidden md:block ${contentwidth}`}
            ></div>
            <div className={`flex flex-col ${contentwidth}`}>
              <p className="sub-heading medium mb-4">
                We would love to hear from you
              </p>
              <div className="flex flex-row gap-4">
                <img src={env} alt="G" className="w-6 md:w-auto" />
                <a
                  href="mailto:acm.pict@gmail.com"
                  className="underline sub-heading-s medium"
                >
                  acm.pict@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#212121" }} className='relative'>
        {open && <Developer setOpen={setOpen} />}
        <div className="flex flex-col px-4 sm:px-8 md:px-12 lg:px-16 py-3 md:py-0 ">
          {/* <p
            className=" md:hidden caption medium mb-2 md:mb-0"
            style={{ color: "#55B8E5" }}
          >
            Designed and Developed with <span role="img">❤️</span>
            by PASC Team
          </p> */}
          <div className="flex flex-row justify-between py-0 md:py-6">
            {/* <a
              href="https://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="caption medium text-white"
            >
              Privacy Policy
            </a> */}
            <span className="hidden md:block"></span>
            <p
              className="caption medium flex gap-1"
              style={{ color: "#55B8E5" }}
            >
              <span>Designed and Developed with</span><span role="img">❤️</span>
              <button className="cursor-pointer" to="/developer" onClick={() => setOpen(!open)}>by PASC Team</button>
            </p>
            <p className="caption medium" style={{ color: "white" }}>Ⓒ PASC, {date.getFullYear()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
