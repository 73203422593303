import React from "react"
import GoogleLogin from "react-google-login"

export const clientId =
  "642421501848-5ck0f7oesdkhvp4bv2inm0andc51ebn2.apps.googleusercontent.com"

const GoogleAuth = props => {
  const { isSignedIn, Component, onSuccess, onFailure, ...rest } = props
  return (
    <GoogleLogin
      clientId={clientId}
      render={renderProps => (
        <Component
          {...rest}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        />
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
    />
  )
}

export default GoogleAuth
