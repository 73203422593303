import axios from "axios"

const axiosInstance = axios.create({
  baseURL: "http://localhost:3000",
})

let API = {}

API.setAuthToken = token => {
  axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`
}

// Login user
API.login = async ({ email, password }) => {
  return await axiosInstance.post(`/users/login`, {
    email,
    password,
  })
}

// Signup user
API.signup = async ({ email, password, name }) => {
  return await axiosInstance.post(`/user/signup`, {
    email,
    password,
    name,
  })
}

// Logout user
API.logout = async () => {
  return await axiosInstance.post(`/users/logout`)
}

// Logout user from all devices
API.logoutAll = async () => {
  return await axiosInstance.post(`/users/logoutAll`)
}

API.getApprovedBlogs = async () => {
  try {
    const res = await axiosInstance.get(`/blogs/approved`)
    return res.data;
  } catch (e) {
    console.log(e);
      if (e?.response?.data) {
        return e.response.data;
      }
      return {
        error: "Something Went Wrong",
      };
  }
}

API.addBlog = async (values) => {
  try {
    const res = await axiosInstance.post(`/createblog`, values)
    return res.data;
  } catch (e) {
    console.log(e);
      if (e?.response?.data) {
        return e.response.data;
      }
      return {
        error: "Something Went Wrong",
      };
  }
}

export default API
