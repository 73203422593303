import React from 'react'
// import { useState } from 'react';
import * as style from '../styles/blogPage.module.css'
import AppButton from './AppButton';

function BlogFilter() {

    // const [clear,setClear] = useState(false);


    const topics = [["Competitive Programming","App Dev","Blockchain","IoT","Research"],
                    ['Web Dev','Design','Content','Cybersecurity','Interview']];
    
    const topicItems = (i) => topics[i].map((topic,index)=>{
        return(
                <div class={`inline-block items-center mt-3 form-check`} key={index}>    
                    <input class={`form-check-input inline-block h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 mb-2 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer ${style.bf_checkbox}`} type="checkbox" value="" id="topic_selection"/>
                    <label class={`form-check-label inline text-gray-800 ${style.bf_topic}`} for="flexCheckDefault">{topic}</label>
                </div>
            )
        });

    function handleClear() {}

    return (
            <div className={`max-w-sm rounded-xl absolute top-0 right-0 shadow-lg z-10 ${style.bf_main}`}>{/* filter card*/}
                
                <div class={`w-8 h-8 rounded-sm absolute right-8 -top-3.5 transform rotate-45 skew-y-20 ${style.bf_pointer}`}></div>
                
                <div class={`px-9 py-8 flex flex-col flex-wrap`}>

                    <div class={`flex flex-row justify-between`}>
                        <div class={`w-full flex flex-col flex-wrap justify-evenly`}>{topicItems(0)}</div>
                        <div class={`w-full flex flex-col flex-wrap justify-evenly`}>{topicItems(1)} </div>
                    </div>
                    
                    <div class={`flex flex-row justify-end items-center gap-8 mt-8 mr-5`}>
                        <button style={{color:"#546E7A",fontWeight:"500",fontSize:"14px",cursor:"pointer"}} onClick={handleClear}>CLEAR</button>
                        {/* <span>Apply</span> */}
                        <AppButton title="APPLY" icon="check" size='small' variant="primary"/>
                    </div>
                </div>

            </div>
    )
}

export default BlogFilter
