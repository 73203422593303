import React, { createContext,  useState } from "react"
const modalContext = createContext("");
modalContext.displayName = "ModalContext"

export const ModalContextProvider = ({children}) => {
    const [open, setOpen] = useState();
    return (
        <modalContext.Provider value={{open, setOpen}}>
            {children}
        </modalContext.Provider>
    )
}

export default modalContext;