import React, { useEffect, useState } from "react"
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import { toTop } from "../utils/scroll"

import data from "../data/gallery.json"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { Helmet } from "react-helmet"
import { event_title } from "../styles/eventcard.module.css"

const Gallery = props => {
  const [open, setOpen] = useState(props.location.hash?.replace("#", ""))
  useEffect(() => {
    toTop()
  }, [])
  console.log(data)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PICT ACM Student Chapter | Gallery</title>
        <meta
          name="description"
          content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future."
        />
      </Helmet>
      <Banner
        title="Glimpses of PASC Activities"
        image="Glimpses.png"
        text="Discover the events that PASC conducts and get to know our members that make the community's technical and creative growth possible."
      />
      <SimpleReactLightbox>
        <div className="py-5 md:py-10 px-4 sm:px-8 md:px-12 lg:px-16">
          <div>
            <h4 className="text-center text-2xl font-bold mb-6">Pulzion</h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
              {data?.pulzion.map(image => (
                <div
                  key={image.original}
                  className="w-full aspect-[4/3] overflow-hidden rounded-xl shadow-xl"
                >
                  <a href={image.original}>
                    <img
                      src={image.thumbnail}
                      alt="Event"
                      className=" h-80 w-full object-cover"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="py-5 md:py-10 px-4 sm:px-8 md:px-12 lg:px-16">
          <div>
            <h4 className="text-center text-2xl font-bold mb-6">
              SIGs and Sessions
            </h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
              {data?.sig.map(image => (
                <div
                  key={image.original}
                  className="w-full aspect-[4/3] overflow-hidden rounded-xl shadow-xl"
                >
                  <a href={image.original}>
                    <img
                      src={image.thumbnail}
                      alt="Event"
                      className=" h-80 w-full object-cover"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </Layout>
  )
}

export default Gallery
