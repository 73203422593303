import React, { useState } from "react";
import { pascalumnis } from "./Join_Pasc_data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/join_pasc.css";

const PascAlumnis = () =>
{
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: 0,
    slidesToShow: 3,
    lazyload: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    beforeChange: (current, next) => setImageIndex(next),
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <div className="relative mt-12">
      <div className={`flex flex-col md:mb-10 xs:mb-6 `}>
        <div className="px-4 sm:px-8 md:px-12 lg:px-16 ">
          <p className={`heading`}>
            Hear it from our Alumni
          </p>
        </div>

        <div className={`lg:block xs:hidden px-4 sm:px-8 md:px-12 lg:px-16 mt-12 mb-0`}>
          <Slider {...settings} className={`alumni-slider`}>
            {pascalumnis.map((pascalumni, idx) =>
            {
              return (
                <div
                  className={
                    idx === imageIndex
                      ? "slide activeSlide flex flex-row mb-4 "
                      : "slide flex flex-row mb-4 "
                  }
                  key={pascalumni.name}
                >
                  <div className="float-left mr-4">
                    {pascalumni.image ? (
                      <img
                        className={` object-cover md:lg:h-full alumniimg`}
                        style={{ height: "380px" }}
                        src={pascalumni.image}
                        alt="name"
                      />
                    ) : null}
                  </div>
                  {pascalumni.image ? (
                    <div
                      className={`flex flex-col justify-between`}
                      style={{ height: "380px" }}
                    >
                      <div>
                        <span className="material-icons">
                          <i style={{ fontSize: "2em" }}>format_quote</i>
                        </span>
                        <p className={`paragraph1 light`}>
                          {pascalumni.description}
                        </p>
                      </div>
                      <div className={`paragraph2 medium mb-4 mr-4`}>
                        <p style={{ float: "right", fontStyle: "italic" }}>
                          - {pascalumni.name}
                        </p>
                        <br />
                        <p style={{ float: "right", fontStyle: "italic" }}>
                          PASC, {pascalumni.year}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={`md:m-10`}>
                      <span className="material-icons">
                        <i style={{ fontSize: "2em" }}>format_quote</i>
                      </span>
                      <p className={`paragraph1 light`}>
                        {pascalumni.description}
                      </p>
                      <div className={`paragraph2 medium mt-5`}>
                        <p style={{ float: "right", fontStyle: "italic" }}>
                          - {pascalumni.name}
                        </p>
                        <br />
                        <p style={{ float: "right", fontStyle: "italic" }}>
                          PASC, {pascalumni.year}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="w-11/12 mx-auto">
          <div className={`xs:block lg:hidden`}>
            <Slider {...settings2}>
              {pascalumnis.map((pascalumni, idx) =>
              {
                return (
                  <div
                    className={
                      idx === imageIndex
                        ? "slide activeSlide grid grid-cols-2"
                        : "slide grid grid-cols-2"
                    }
                    key={pascalumni.name}
                  >
                    {pascalumni.image ? (
                      <img
                        className={` rounded-tl-lg rounded-tr-lg md:lg: w-full object-cover h-96`}
                        src={pascalumni.image}
                        alt={pascalumni.name}
                      />
                    ) : null}
                    <div className={`md:m-10 xs:m-3`}>
                      <span className="material-icons">
                        <i style={{ fontSize: "2em" }}>format_quote</i>
                      </span>
                      <p className={`paragraph1 light`}>
                        {pascalumni.description}
                      </p>
                      <div className={`paragraph2 medium mt-5 pb-4`}>
                        <p style={{ float: "right", fontStyle: "italic" }}>
                          - {pascalumni.name}
                        </p>
                        <br />
                        <p style={{ float: "right", fontStyle: "italic" }}>
                          PASC, {pascalumni.year}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PascAlumnis;
