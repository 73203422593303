import chairman from "../../static/Team/2021/chairman.jpg"
import cp from "../../static/Team/2021/cp.jpeg"
import design from "../../static/Team/2021/design.png"
import marketting from "../../static/Team/2021/marketting.jpeg"
import mobile from "../../static/Team/2021/mobile.jpg"
import pro from "../../static/Team/2021/pro.jpg"
import secretary from "../../static/Team/2021/secretary.jpeg"
import tech01 from "../../static/Team/2021/tech01.png"
import tech02 from "../../static/Team/2021/tech02.jpeg"
import tg from "../../static/Team/2021/tg.jpg"
import treasurer from "../../static/Team/2021/treasurer.jpeg"

import vice_chair from "../../static/Team/2021/vice_chair.jpg"
import web_lead from "../../static/Team/2021/web_lead.png"

export const ourTeamData = [
  {
    name: "Dr. Geetanjali",
    surname: "Kale",
    intro: "Lorem ipsum",
    imageUrl: tg,
    designation: "Teacher Guardian",
    socials: {
      linkedin: "https://www.linkedin.com/in/dr-geetanjali-kale-17148922/",
    },
    year: "3021",
  },
  {
    name: "Jinesh",
    surname: "Parakh",
    intro: "Lorem ipsum",
    imageUrl: chairman,
    designation: "Chairperson",
    socials: {
      github: "https://github.com/jineshparakh",
      linkedin: "https://www.linkedin.com/in/jinesh-parakh/",
      instagram: "instagram.url",
    },
    year: "2021",
  },
  {
    name: "Rohit",
    surname: "Pardeshi",
    intro: "Lorem ipsum",
    imageUrl: vice_chair,
    designation: "Vice Chairperson",
    socials: {
      github: "https://github.com/rohitpardeshi04",
      linkedin: "https://www.linkedin.com/in/rohit-pardeshi-04/",
    },
    year: "2021",
  },
  {
    name: "Atharv",
    surname: "Chavan",
    intro: "Lorem ipsum",
    imageUrl: secretary,
    designation: "Secretary",
    socials: {
      github: "https://github.com/AVC0706",
      linkedin: "https://www.linkedin.com/in/avc0706/",
    },
    year: "2021",
  },
  {
    name: "Siddhesh",
    surname: "Dhanavade",
    intro: "Lorem ipsum",
    imageUrl: treasurer,
    designation: "Treasurer",
    socials: {
      github: "https://github.com/Siddhesh-D",
      linkedin: "https://linkedin.com/in/siddhesh-dhanavde-3079b3185",
      instagram: "https://www.instagram.com/siddhesh_10.7/",
    },
    year: "2021",
  },
  {
    name: "Aditya",
    surname: "Avhad",
    intro: "Lorem ipsum",
    imageUrl: pro,
    designation: "Public Relations Officer",
    socials: {
      linkedin: "https://www.linkedin.com/in/aditya-avhad-46a7a81b9/",
    },
    year: "2021",
  },
  {
    name: "Pranav",
    surname: "Kadam",
    intro: "Lorem ipsum",
    imageUrl: marketting,
    designation: "Marketing Head",
    socials: {
      linkedin: "https://www.linkedin.com/in/pranav-kadam-1317a2187/",
    },
    year: "2021",
  },
  {
    name: "Soumya",
    surname: "Malgonde",
    intro: "Lorem ipsum",
    imageUrl: tech02,
    designation: "Technical Head",
    socials: {
      github: "https://github.com/SoumyaMalgonde",
      linkedin: "https://www.linkedin.com/in/soumya-malgonde-460480150/",
    },
    year: "2021",
  },
  {
    name: "Pratik",
    surname: "Daigavane",
    intro: "Lorem ipsum",
    imageUrl: tech01,
    designation: "Technical Head",
    socials: {
      github: "https://github.com/pratikdaigavane",
      linkedin: "https://www.linkedin.com/in/pratikdaigavane/",
      instagram: "instagram.url",
    },
    year: "2021",
  },
  {
    name: "Kunal",
    surname: "Raut",
    intro: "Lorem ipsum",
    imageUrl: cp,
    designation: "Domain Director (Competitive Programming)",
    socials: {
      github: "https://www.github.com/zukonit14",
      linkedin: "https://www.linkedin.com/in/zukonit14",
      instagram: "https://www.instagram.com/kunalraut__/?r=nametag",
    },
    year: "2021",
  },
  {
    name: "Prathamesh",
    surname: "Shiralkar",
    intro: "Lorem ipsum",
    imageUrl: web_lead,
    designation: "Domain Director (Web & DevOps)",
    socials: {
      github: "https://github.com/pnshiralkar",
      linkedin: "https://www.linkedin.com/in/pnshiralkar",
      instagram: "https://www.instagram.com/pratham__99",
    },
    year: "2021",
  },
  {
    name: "Aniruddha",
    surname: "Deshmukh",
    intro: "Lorem ipsum",
    imageUrl: mobile,
    designation: "Domain Director (Android)",
    socials: {
      github: "https://github.com/Aniruddha004",
      linkedin: "https://www.linkedin.com/in/aniruddha-deshmukh/",
      instagram: "instagram.url",
    },
    year: "2021",
  },
  {
    name: "Abhishek",
    surname: "Kushwaha",
    intro: "Lorem ipsum",
    imageUrl: design,
    designation: "Design Head",
    socials: {
      linkedin: "https://www.linkedin.com/in/kushwaha-abhishek/",
    },
    year: "2021",
  },
  {
    name: "Ashutosh",
    surname: "Shaha",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/6Zr4VTa.jpg",
    designation: "Chairperson",
    socials: {
      github: "https://github.com/ashutosh264/",
      linkedin: "https://www.linkedin.com/in/ashutosh-shaha/",
      instagram: "https://www.instagram.com/ashutosh_264/",
    },
    year: "2022",
  },
  {
    name: "Siddhi",
    surname: "Wakchaure",
    intro: "",
    imageUrl:
      "https://i.imgur.com/wgo56lh.jpg",
    designation: "Vice Chairperson",
    socials: {
      github: "https://github.com/SiddhiWakchaure",
      linkedin: "https://www.linkedin.com/in/siddhi-wakchaure-98b147198/",
      instagram: "https://www.instagram.com/siddhi_wakchaure",
    },
    year: "2022",
  },
  {
    name: "Rohin",
    surname: " Bhat",
    intro: "",
    imageUrl:
      "https://i.imgur.com/DrQKBgx.jpg",
    designation: "Secretary",
    socials: {
      github: "https://github.com/RohinBhat",
      linkedin: "https://www.linkedin.com/in/rohinbhat"
    },
    year: "2022",
  },
  {
    name: "Bhavana",
    surname: "Bafna",
    intro: "",
    imageUrl:
      "https://i.imgur.com/qMVRJNd.jpg",
    designation: "Treasurer",
    socials: {
      github: "https://github.com/Bhavana568",
      linkedin: "https://www.linkedin.com/in/bhavana-bafna-2b80991a6",
      instagram: "https://www.instagram.com/bafna_bhavana123/?utm_medium=copy_link",
    },
    year: "2022",
  },
  {
    name: "Sharayu",
    surname: "Hiwarkhedkar",
    intro: "",
    imageUrl:
      "https://i.imgur.com/XtF0mWQ.jpg",
    designation: "Public Relations Officer",
    socials: {
      github: "https://github.com/sharayuH",
      linkedin: "https://www.linkedin.com/in/sharayu-hiwarkhedkar-996a8b1b0",
      instagram: "https://www.instagram.com/sharayuhiwarkhedkar/",
    },
    year: "2022",
  },
  {
    name: "Vimal",
    surname: "Galani",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/gctrKTy.jpg",
    designation: "Technical Head",
    socials: {
      github: "https://github.com/vimalgalani28",
      linkedin: "https://www.linkedin.com/in/vimal-galani-a7b977199/",
      instagram: "https://www.instagram.com/vimal_galani/",
    },
    year: "2022",
  },
  {
    name: "Rugved",
    surname: "Somwanshi",
    intro: "",
    imageUrl:
      "https://i.imgur.com/MYeC06A.jpg",
    designation: "Technical Head",
    socials: {
      github: "https://github.com/rugz007",
      linkedin: "https://www.linkedin.com/in/rugved-somwanshi-29078417b/"
    },
    year: "2022",
  },
  {
    name: "Akshat",
    surname: "Sharma",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/KY2e5gD.jpg",
    designation: "Domain Director (Web & DevOps)",
    socials: {
      // github: "https://github.com/adityakangune",
      linkedin: "https://www.linkedin.com/in/akshat-sharma-2602/"
    },
    year: "2022",
  },
  {
    name: "Aditya",
    surname: "Kangune",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/zKht3vZ.jpg",
    designation: "Domain Director (ML & AI)",
    socials: {
      github: "https://github.com/adityakangune",
      linkedin: "https://www.linkedin.com/in/aditya-kangune-0376671aa/",
      instagram: "https://www.instagram.com/aditya_kangune/"
    },
    year: "2022",
  },
  {
    name: "Ojas",
    surname: "Kund",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/ueWUJFE.jpg",
    designation: "Domain Director (Competitive Programming)",
    socials: {
      // github: "https://github.com/RohinBhat",
      linkedin: "https://www.linkedin.com/in/ojas-kund/",
      instagram: "https://www.instagram.com/ojaskund/",
    },
    year: "2022",
  },
  {
    name: "Aagaaz Ali",
    surname: "Sayed",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/YVvRzwF.jpg",
    designation: "Marketing Head",
    socials: {
      github: "https://github.com/Augusteen66",
      linkedin: "https://www.linkedin.com/in/aagaaz-ali-sayed-1063851ba/",
      instagram: "https://www.instagram.com/augusteen66/",
    },
    year: "2022",
  },
  {
    name: "Sakshi",
    surname: "Harode",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/Sczmb38.jpg",
    designation: "Design Head",
    socials: {
      github: "https://github.com/harodesakshi",
      linkedin: "https://www.linkedin.com/in/sakshi-harode/",
      instagram: "https://www.instagram.com/_sakshi_harode_11/",
    },
    year: "2022",
  },
  {
    name: "Aditi",
    surname: "Chavan",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/aditi.jpg",
    designation: "Chairperson",
    socials: {
      // github: "https://github.com/harodesakshi",
      linkedin: "https://www.linkedin.com/in/aditi-chavan-439b44206",
      // instagram: "https://www.instagram.com/_sakshi_harode_11/",
    },
    year: "2023",
  },
  {
    name: "Om",
    surname: "Shinde",
    intro: "",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/Om+Shinde.png",
    designation: "Vice Chairperson",
    socials: {
      // github: "https://github.com/SiddhiWakchaure",
      linkedin: "https://www.linkedin.com/in/om-shinde-0aabb1207",
      // instagram: "https://www.instagram.com/siddhi_wakchaure",
    },
    year: "2023",
  },
  {
    name: "Tejas",
    surname: "Padhiyar",
    intro: "",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/tejas_square.jpg",
    designation: "Secretary",
    socials: {
      // github: "https://github.com/RohinBhat",
      linkedin: "https://www.linkedin.com/in/tejas-padhiyar"
    },
    year: "2023",
  },
  {
    name: "Shivani",
    surname: "Makde",
    intro: "",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/Shivani.jpeg",
    designation: "Treasurer",
    socials: {
      // github: "https://github.com/Bhavana568",
      linkedin: "https://www.linkedin.com/in/shivani-makde",
      // instagram: "https://www.instagram.com/bafna_bhavana123/?utm_medium=copy_link",
    },
    year: "2023",
  },
  {
    name: "Amar",
    surname: "Dalvi",
    intro: "",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/IMG_20221231_194923_381.jpg",
    designation: "Public Relations Officer",
    socials: {
      // github: "https://github.com/sharayuH",
      linkedin: "https://www.linkedin.com/in/amar-dalvi-ba5b36208",
      // instagram: "https://www.instagram.com/sharayuhiwarkhedkar/",
    },
    year: "2023",
  },
  {
    name: "Pratham",
    surname: "More",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/20200731_223548.jpg",
    designation: "Technical Head",
    socials: {
      // github: "https://github.com/vimalgalani28",
      linkedin: "https://www.linkedin.com/in/pratham-more-7688571b5/",
      // instagram: "https://www.instagram.com/vimal_galani/",
    },
    year: "2023",
  },
  {
    name: "Suyog",
    surname: "Kokaje",
    intro: "",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/suyog.jpg",
    designation: "Technical Head",
    socials: {
      // github: "https://github.com/rugz007",
      linkedin: "https://www.linkedin.com/in/suyog-kokaje-bb54aa212/"
    },
    year: "2023",
  },
  {
    name: "Yash",
    surname: "Kalavadiya",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/Yash+Kalavadiya.png",
    designation: "Domain Director (Web & DevOps)",
    socials: {
      // github: "https://github.com/adityakangune",
      linkedin: "https://www.linkedin.com/in/yash-kalavadiya-789141170/"
    },
    year: "2023",
  },
  {
    name: "Yash",
    surname: "Sonawane",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/IMG_0873.JPG",
    designation: "Domain Director (Competitive Programming)",
    socials: {
      // github: "https://github.com/RohinBhat",
      linkedin: "https://www.linkedin.com/in/yash-sonawane-a49870202",
      // instagram: "https://www.instagram.com/ojaskund/",
    },
    year: "2023",
  },
  {
    name: "Mokshad",
    surname: "Vaidya",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/Mokshad+Vaidya.jpeg",
    designation: "Domain Director (App Dev)",
    socials: {
      // github: "https://github.com/RohinBhat",
      linkedin: "https://www.linkedin.com/in/mokshadvaidya",
      // instagram: "https://www.instagram.com/ojaskund/",
    },
    year: "2023",
  },
  {
    name: "Aishwarya",
    surname: "Mirashi",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/IMG_20230329_160339.jpg",
    designation: "Domain Director (ML & AI)",
    socials: {
      // github: "https://github.com/adityakangune",
      linkedin: "https://www.linkedin.com/in/aishwarya-mirashi-620534215",
      // instagram: "https://www.instagram.com/aditya_kangune/"
    },
    year: "2023",
  },
  {
    name: "Dheeraj",
    surname: "Maske",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/dheeraj.jpeg",
    designation: "Marketing Head",
    socials: {
      // github: "https://github.com/Augusteen66",
      linkedin: "https://www.linkedin.com/in/dheeraj-maske-b47343200",
      // instagram: "https://www.instagram.com/augusteen66/",
    },
    year: "2023",
  },
  {
    name: "Ayush",
    surname: "Gala",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://i.imgur.com/WEe6vLX.jpg",
    designation: "Internal Engagement Officer",
    socials: {
      // github: "https://github.com/Augusteen66",
      linkedin: "https://in.linkedin.com/in/ayush-gala-b66b7220b",
      // instagram: "https://www.instagram.com/augusteen66/",
    },
    year: "2023",
  },
  {
    name: "Akanksha",
    surname: "Waghmare",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/Akanksha.jpg",
    designation: "Creative Head",
    socials: {
      // github: "https://github.com/harodesakshi",
      linkedin: "https://www.linkedin.com/in/akanksha-waghmare-b53394224",
      // instagram: "https://www.instagram.com/_sakshi_harode_11/",
    },
    year: "2023",
  },
  {
    name: "Prasenjeet",
    surname: "Sonawane",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://pasc-main-images.s3.ap-south-1.amazonaws.com/out-PhotoRoom+(1).png",
    designation: "Creative Head",
    socials: {
      // github: "https://github.com/harodesakshi",
      linkedin: "https://www.linkedin.com/in/prasenjeet-sonawane-856b23205/",
      // instagram: "https://www.instagram.com/_sakshi_harode_11/",
    },
    year: "2023",
  },
	{
    name: "Aditya",
    surname: "More",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FIMG-20240209-WA0451.jpg?alt=media&token=9019a436-a2ca-4c79-9cda-2f7863ccbc28",
    designation: "Chairperson",
    socials: {
      github: "https://github.com/AdityaMore7000",
      linkedin: "https://www.linkedin.com/in/aditya-more-9a095422a/",
      instagram: "https://www.instagram.com/adityamore07003?igsh=bnE2dzhvZTE5N2ps",
    },
    year: "2024",
  },
  {
    name: "Aditi",
    surname: "Date",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FIMG-20231031-WA0074.jpg?alt=media&token=cbffd768-e9d1-4234-800d-46d1f5e14a4e",
    designation: "Vice Chairperson",
    socials: {
      github: "https://github.com/ADITI152003",
      linkedin: "https://www.linkedin.com/in/aditi-date-43a16022a/",
      instagram: "https://www.instagram.com/aditidate_1509?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    },
    year: "2024",
  },
  {
    name: "Pritika",
    surname: "Rohera",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FPritika.jpg?alt=media&token=1180a67e-ebf7-4aba-beff-89b46e752fb4",
    designation: "Secretary",
    socials: {
      github: "https://github.com/PRITIKA10",
      linkedin: "https://www.linkedin.com/in/pritikarohera/",
      instagram: "https://www.instagram.com/pritikarohera/?utm_source=ig_web_button_share_sheet",
    },
    year: "2024",
  },
  {
    name: "Charul",
    surname: "Nampalliwar",
    intro: "",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/pulzion-team-page.appspot.com/o/Leads%2FCharul%20Nampalliwar.jpeg?alt=media&token=2ca82dda-5ae3-499c-8052-2a8424e8604c&_gl=1*7hqilr*_ga*NjI2MTcyMDc3LjE2OTI1ODY4OTM.*_ga_CW55HF8NVT*MTY5NzU1MTUxNi4xMi4xLjE2OTc1NTIyNjQuNTIuMC4w",
    designation: "Treasurer",
    socials: {
      github: "https://github.com/Charul2304",
      linkedin: "https://www.linkedin.com/in/charul-nampalliwar-43148b22a/",
      instagram: "https://www.instagram.com/charul_2304/",
    },
    year: "2024",
  },
  {
    name: "Pranav",
    surname: "Jaju",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FPranav%20Technical%20head.jpg?alt=media&token=fc86b4ab-6a82-471a-8707-ebca3668cc01",
    designation: "Technical Head",
    socials: {
      github: "https://github.com/PranavJaju",
      linkedin: "https://www.linkedin.com/in/pranav-jaju-7a7635229/",
      instagram: "https://www.instagram.com/pranav_jaju_07/",
    },
    year: "2024",
  },
  {
    name: "Mustafa",
    surname: "Trunkwala",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2F1000045158-03.jpeg?alt=media&token=632641d4-9da6-45bc-be50-e52ee0f7e4a5",
    designation: "Technical Head",
    socials: {
      github: "https://github.com/TechyMT",
      linkedin: "https://www.linkedin.com/in/techymt/",
      instagram: "https://www.instagram.com/nothim_7856/",
    },
    year: "2024",
  },
  {
    name: "Rohit",
    surname: "Kuvar",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FRohit%20Kuvar.JPG?alt=media&token=e7cd5636-b8e7-488c-9cbd-23872bf83fd1",
    designation: "Public Relations Officer",
    socials: {
      github: "https://github.com/rohit02rk",
      linkedin: "https://www.linkedin.com/in/rohit-kuvar-821309136/",
      instagram: "https://www.instagram.com/rk.rohit_2/",
    },
    year: "2024",
  },
  {
    name: "Om",
    surname: "Lachure",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FOm.jpg?alt=media&token=e48419d8-3bf1-47a5-ad26-224094d772d1",
    designation: "Marketing Head",
    socials: {
      // github: "",
      linkedin: "https://www.linkedin.com/in/om-lachure-644104264/",
      instagram: "https://www.instagram.com/the_chosen_wizard_/",
    },
    year: "2024",
  },
  {
    name: "Ayush",
    surname: "Bulbule",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FAyushBulbule.JPG?alt=media&token=5117347b-2ff6-4d6d-a922-c048c6b77c72",
    designation: "Domain Director (Web & DevOps)",
    socials: {
      github: "https://github.com/Ayush-Bulbule",
      linkedin: "https://www.linkedin.com/in/ayushbulbule/",
      instagram: "https://www.instagram.com/ayush_2413/",
    },
    year: "2024",
  },
  {
    name: "Aniket",
    surname: "Kolte",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FIMG-20240221-WA0001.jpg?alt=media&token=fd182cd1-a7db-4b1f-ae91-e63044f46566",
    designation: "Domain Director (Competitive Programming)",
    socials: {
      // github: "",
      linkedin: "https://www.linkedin.com/in/aniketkolte79/",
      instagram: "https://www.instagram.com/aniketkolte_/",
    },
    year: "2024",
  },
  {
    name: "Awdhoot",
    surname: "Kutwad",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FAwadhoot-kata_hua.jpeg?alt=media&token=c420c5ba-fbba-4071-b215-0b05ec4a5cfc",
    designation: "Domain Director (Android)",
    socials: {
      github: "https://github.com/AwadhootK",
      linkedin: "https://www.linkedin.com/in/awadhoot-khutwad-47879a22a/",
      instagram: "https://www.instagram.com/ak_._83/",
    },
    year: "2024",
  },
  {
    name: "Atharva",
    surname: "Date",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2F20231017_142048.jpg?alt=media&token=7f231d64-9b9f-4d45-9111-6f6986b13243",
    designation: "Domain Director (ML & AI)",
    socials: {
      github: "https://github.com/Atharva9621",
      linkedin: "https://www.linkedin.com/in/atharva-date-04345b256/",
      instagram: "https://www.instagram.com/atharva1_618/",
    },
    year: "2024",
  },
  {
    name: "Soumya",
    surname: "Garg",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FSoumya%20Garg.jpg?alt=media&token=a7828028-0059-4793-9d1f-de351d1c8574",
    designation: "Internal Engagement Officer",
    socials: {
      github: "https://github.com/Soumyagarg29",
      linkedin: "https://www.linkedin.com/in/soumya-garg-3a7453166/",
      instagram: "https://www.instagram.com/_soumya.garg/",
    },
    year: "2024",
  },
  {
    name: "Mikhiel",
    surname: "Benji",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FMikhiel_Sujeet_Benji.png?alt=media&token=93f11f04-6725-4b33-b912-80bf64848ac1",
    designation: "Creative Head",
    socials: {
      github: "https://github.com/Mikhiel39",
      linkedin: "https://www.linkedin.com/in/mikhielsbenji/",
      instagram: "https://www.instagram.com/mikhielbenji/",
    },
    year: "2024",
  },
  {
    name: "Shraddha",
    surname: "Asolkar",
    intro: "Lorem ipsum1",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fir-storage-e7daa.appspot.com/o/Council%202024-25%2FShradha.jpg?alt=media&token=b7c1f6f0-79cf-4edb-b48e-c67daded6086",
    designation: "Creative Head",
    socials: {
      // github: "https://github.com/Mikhiel39",
      linkedin: "https://www.linkedin.com/in/shraddha-asolkar-1a7104236/",
      instagram: "https://www.instagram.com/shradhslays_/",
    },
    year: "2024",
  }
]
