import sponsor_01 from "../../static/sponsors/sponsor_01.png"
import sponsor_02 from "../../static/sponsors/sponsor_02.png"
import sponsor_03 from "../../static/sponsors/sponsor_03.png"
import sponsor_04 from "../../static/sponsors/sponsor_04.png"
import sponsor_05 from "../../static/sponsors/sponsor_05.png"
import sponsor_06 from "../../static/sponsors/sponsor_06.webp"
import sponsor_07 from "../../static/sponsors/sponsor_07.jpg"
import sponsor_08 from "../../static/sponsors/sponsor_08.png"
import sponsor_09 from "../../static/sponsors/sponsor_09.png"
import sponsor_10 from "../../static/sponsors/sponsor_10.png"
import sponsor_11 from "../../static/sponsors/sponsor_11.png"
import sponsor_12 from "../../static/sponsors/pluto.png"
export const sponsors = [
  {
    url: sponsor_01,
  },
  {
    url: sponsor_02,
  },
  {
    url: sponsor_03,
  },
  {
    url: sponsor_04,
  },
  {
    url: sponsor_05,
  },
  {
    url: sponsor_06,
  },
  {
    url: sponsor_07,
  },
  {
    url: sponsor_08,
  },
  {
    url: sponsor_12,
  },
  {
    url: sponsor_09,
  },
  {
    url: sponsor_10,
  },
  {
    url: sponsor_11,
  },
]
