import React from "react"
import {Helmet} from "react-helmet";

import Benefits from "../components/Benefits"
import PascAlumnis from "../components/Pasc_alumnis"
import Layout from "../components/Layout"
import AppButton from "../components/AppButton"
import { joinCTAContainer, joinCTAText } from "../styles/home.module.css"
import JoinBanner from "../components/Join_banner_new"
import AluminiSection from "../components/AluminiSection"
import Tooltip from "../components/Tooltip";

const Join = () => {
  const isBrowser = typeof window !== "undefined"
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PICT ACM Student Chapter | Join Pasc</title>
        <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <JoinBanner />
      <Benefits />
      {/* <div id="Alumni -Form">
      <AluminiSection/>
      </div> */}
      <PascAlumnis />

      <div
        className={`${joinCTAContainer} px-4 sm:px-8 md:px-12 lg:px-16 py-4 flex items-center justify-between gap-4 `}
      >
        <p
          className={`${joinCTAText} font-normal md:font-light text-sm md:text-3xl`}
        >
          Become a part of such awesomeness
        </p>
          <AppButton
            variant="primary"
            size="small"
            title="JOIN PASC"
            
            onClick={() => {
              if (isBrowser) {
                window?.open("https://pict.acm.org/register/")
              }
            }}
            
          />
      </div>
    </Layout>
  )
}

export default Join
